import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import AngelsLanding from "./AngelsLanding4836.JPG";
import Backyard from "./Backyard1418.jpg";
import Convergence from "./Convergence2418.jpg";
import Cousins from "./Cousins1824.jpg";
import Family from "./Family2016.JPG";
import Flavor from "./Flavor4860.JPG";
import GenerationBeach from "./GenerationBeach1620.JPG";
import Reflections from "./Reflections:AStudyInPointillism1418.jpg";
import SantaMonica from "./SantaMonica2436.jpg";
import StLouisSkyline from "./StLouisSkyline1530.JPG";
import SunsetSeries from "./SunsetSeries.jpg";
import SuperBloom from "./SuperBloom2436.JPG";
import UntitledOil from "./UntitledOil.JPG";
import TheHope from "./TheHope2430.jpg";

const Carousel = () => {
  return ( 
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={150}
      totalSlides={14}
      className="carousel"
    //   visibleSlides={3}
    >
    <ButtonBack className="button">Back</ButtonBack>
    <ButtonNext className="button">Next</ButtonNext>
      <Slider>
        <Slide index={0}>
            <h3>Angel's Landing 48"x36"</h3>
            <p><img src={AngelsLanding} alt="Angel's Landing" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={1}>
            <h3>Backyard 14"x18"</h3>
            <p><img src={Backyard} alt="Backyard" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={2}>
            <h3>Convergence 24"x18"</h3>
            <p><img src={Convergence} alt="Convergence" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={3}>
            <h3>Cousins 18"x24"</h3>
            <p><img src={Cousins} alt="Cousins" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={4}>
            <h3>Family 20"x16"</h3>
            <p><img src={Family} alt="Family" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={5}>
            <h3>Flavor 48"x60"</h3>
            <p><img src={Flavor} alt="Flavor" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={6}>
            <h3>Generation Beach 16"x20"</h3>
            <p><img src={GenerationBeach} alt="Generation Beach" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={7}>
            <h3>Reflections: A Study in Pointillism 14"x18"</h3>
            <p><img src={Reflections} alt="Reflections: A Study in Pointillism" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={8}>
            <h3>Santa Monica 24"x36"</h3>
            <p><img src={SantaMonica} alt="Santa Monica" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={9}>
            <h3>St. Louis Skyline 15"x30"</h3>
            <p><img src={StLouisSkyline} alt="St. Louis Skyline" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={10}>
            <h3>Sunset/Moonrise Series 20"x16"</h3>
            <p><img src={SunsetSeries} alt="Sunset Moonrise Series" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={11}>
            <h3>Super Bloom 24"x36"</h3>
            <p><img src={SuperBloom} alt="Super Bloom" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={12}>
            <h3>Untitled 24"x18"</h3>
            <h3>Oil on Canvas</h3>
            <p><img src={UntitledOil} alt="Untitled" className="sliderimg" height="auto" width="85%"/></p>
        </Slide>
        <Slide index={13}>
            <h3>The Hope 24"x30"</h3>
            <h3>Oil on Canvas</h3>
            <p><a href="https://www.etsy.com/listing/1690426738/the-hopehatikvah-jewish-peace-israel?variation0=4389931124" target="_blank" rel="noreferrer noopener"><img src={TheHope} alt="The Hope" className="sliderimg" height="auto" width="85%"/></a></p>
        </Slide>
      </Slider>
      
    </CarouselProvider>
  );
};

export default Carousel;