import './App.css';
import { Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Carousel from "./Carousel";

function App() {
  return (
    <div className="App">
      <div className="header">
      <p className="title">Yon Douek Art</p>
      <header className="header">
        <span><Link to="/">Home</Link></span>
        <span><Link to="about">About</Link></span>
        <span><Link to="artwork">Artwork</Link></span>
        <span><Link to="contact">Contact</Link></span>
      </header>
      </div>
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="about" element={ <About/> } />
        <Route path="artwork" element={ <Carousel/>} />
        <Route path="contact" element={ <Contact/> } />
      </Routes>
    </div>
  );
}

export default App;
