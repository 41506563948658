

export default function About () {
    return (
        <div>
            <div className="bio">
                <h1>Introducing Yon Douek</h1>
                <h2 style={{fontSize: "18px", marginLeft: "10%", marginRight: "10%"}}>A rising artist whose passion for art is deeply rooted in family tradition. With a legacy of creativity passed down through generations, Yon's journey into the world of art began in 2017 when he first picked up a paintbrush. His unique style has blossomed over time, transitioning from the vibrant strokes of acrylic to the rich textures of oil, resulting in a captivating body of work that reflects both his heritage and his personal artistic vision. Explore Yon's world of artistry, where tradition meets innovation, and discover the enchanting stories told through his paintings.</h2>
                <h2 style={{fontSize: "15px"}}>All pieces are acrylic on canvas unless otherwise noted.</h2>
            </div>
        </div>
    )
}